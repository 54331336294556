<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="drawer"
    :dark="barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)'"
    :expand-on-hover="expandOnHover"
    :right="$vuetify.rtl"
    :src="barImage"
    mobile-breakpoint="960"
    app
    mini-variant-width="80"
    width="260"
    v-bind="$attrs"
  >
    <template v-slot:img="props">
      <v-img :gradient="`to bottom, ${barColor}`" v-bind="props" />
    </template>

    <v-list-item two-line>
      <v-list-item-content>
        <v-list-item-title class="text-uppercase font-weight-regular display-2">
          <!-- <span class="logo-mini">SAMI</span> -->
          <span class="logo-normal"> SAMI - HUST</span>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-divider class="mb-1" />

    <v-list dense nav>
      <base-item-group :item="profile" />
    </v-list>

    <v-divider class="mb-2" />

    <v-list expand nav>
      <div />

      <template v-for="(item, i) in computedItems">
        <base-item-group v-if="item.children" :key="`group-${i}`" :item="item">
        </base-item-group>

        <base-item v-else :key="`item-${i}`" :item="item" />
      </template>

      <div />
    </v-list>
  </v-navigation-drawer>
</template>

<script>
// Utilities
import { mapGetters } from "vuex";

export default {
  name: "DashboardCoreDrawer",

  props: {
    expandOnHover: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({}),

  computed: {
    items() {
      if (this.$route.path === "/admin/supervisors/monitoring") {
        return [
          {
            icon: "mdi-briefcase-search-outline",
            title: "Giám sát thi",
            to: "/admin/supervisors/monitoring",
          },
        ];
      }
      if (this.$route.path.startsWith("/admin/original-exams-by-officer")) {
        return [
          {
            icon: "mdi-book-outline",
            title: "Đề gốc",
            to: "/admin/original-exams-by-officer",
          },
        ];
      }
      if (this.$route.path.startsWith("/admin/assistants/monitoring")) {
        return [
          {
            icon: "mdi-account-clock-outline",
            title: "Giám sát giám thị",
            to: "/admin/assistants/monitoring",
          },
        ];
      }
      return [
        {
          icon: "mdi-bookshelf",
          title: "Học phần",
          to: "/admin/subjects",
        },
        {
          icon: "mdi-book-outline",
          title: "Đề gốc",
          to: "/admin/original-exams",
        },
        {
          icon: "mdi-briefcase-outline",
          title: "Kíp thi",
          to: "/admin/examinations",
        },
        {
          icon: "mdi-book-plus-multiple-outline",
          title: "Sinh đề",
          to: "/admin/exam-generation",
        },
        {
          icon: "mdi-book-multiple-outline",
          title: "Đề thi",
          to: "/admin/exams",
        },
        {
          icon: "mdi-briefcase-search-outline",
          title: "Giám sát thi",
          to: "/admin/exam-monitoring",
        },
        {
          icon: "mdi-account",
          title: "Cán bộ",
          to: "/admin/officers",
        },
        {
          icon: "mdi-account",
          title: "Cán bộ ra đề",
          to: "/admin/officers-by-examinations",
        },
        {
          icon: "mdi-home-outline",
          title: "Phòng thi",
          to: "/admin/rooms",
        },
        {
          icon: "mdi-file-chart-outline",
          title: "Bài thi",
          to: "/admin/forms",
        },
      ]
        .concat(
          this.$store.state.user.user.roles.includes("Sa")
            ? [
                {
                  icon: "mdi-account-tie-outline",
                  title: "Ban điều hành",
                  to: "/admin/operators",
                },
                {
                  icon: "mdi-account-tie",
                  title: "Giảng viên",
                  to: "/admin/lecturers",
                },
                {
                  icon: "mdi-assistant",
                  title: "Trợ lý",
                  to: "/admin/assistants",
                },
                {
                  icon: "mdi-cog-outline",
                  title: "Triển khai",
                  to: "/admin/deploy",
                },
              ]
            : []
        )
        .concat(
          this.$store.state.user.user.roles.includes("A")
            ? [
                {
                  icon: "mdi-account-tie",
                  title: "Giảng viên",
                  to: "/admin/lecturers",
                },
                {
                  icon: "mdi-assistant",
                  title: "Trợ lý",
                  to: "/admin/assistants",
                },
              ]
            : []
        )
    },
    ...mapGetters(["barColor", "barImage", "name"]),

    drawer: {
      get() {
        return this.$store.state.app.drawer;
      },
      set(val) {
        this.$store.commit("app/SET_DRAWER", val);
      },
    },
    computedItems() {
      return this.items.map(this.mapItem);
    },
    profile() {
      const user = this.$store.state.user.user;

      const children = user.roles.includes("Sa")
        ? [
            {
              to: "admin/profile",
              title: "Hồ sơ cá nhân",
            },
          ]
        : [];
      children.push({
        to: "logout",
        title: "Đăng xuất",
      });
      return {
        avatar: true,
        url: `https://ui-avatars.com/api/?name=${user.name}`,
        group: "",
        title: user.name,
        children,
      };
    },
  },

  watch: {
    "$vuetify.breakpoint.smAndDown"(val) {
      this.$emit("update:expandOnHover", !val);
    },
  },

  methods: {
    mapItem(item) {
      return {
        ...item,
        children: item.children ? item.children.map(this.mapItem) : undefined,
        title: item.title,
      };
    },
  },
};
</script>

<style lang="sass">
@import '~vuetify/src/styles/tools/_rtl.sass'
#core-navigation-drawer
  &.v-navigation-drawer--mini-variant
    .v-list-item
      justify-content: flex-start !important

    .v-list-group--sub-group
      display: block !important

  .v-list-group__header.v-list-item--active:before
    opacity: .24

  .v-list-item
    &__icon--text,
    &__icon:first-child
      justify-content: center
      text-align: center
      width: 20px

      +ltr()
        margin-right: 24px
        margin-left: 12px !important

      +rtl()
        margin-left: 24px
        margin-right: 12px !important

  .v-list--dense
    .v-list-item
      &__icon--text,
      &__icon:first-child
        margin-top: 10px

  .v-list-group--sub-group
    .v-list-item
      +ltr()
        padding-left: 8px

      +rtl()
        padding-right: 8px

    .v-list-group__header
      +ltr()
        padding-right: 0

      +rtl()
        padding-right: 0

      .v-list-item__icon--text
        margin-top: 19px
        order: 0

      .v-list-group__header__prepend-icon
        order: 2

        +ltr()
          margin-right: 8px

        +rtl()
          margin-left: 8px
</style>
